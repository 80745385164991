<template>
  <div v-if="showCategories"
       class="categories-list fixed wh100 z10 flex left0 top0"
       cy="categories-list-component">
    <div>
      <!-- Left side curved shape -->
      <svg width="89" height="785" viewBox="0 0 89 785" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 777.479C0.0693665 777.492 0.109787 777.5 0.109787 777.5L0.105179 777.277C4.24127 777.85 15.9862 779.664 45.8343 784.518C71.9794 788.77 63.9056 667.329 53.9939 518.244C40.2817 311.994 23.052 52.8362 88.0415 0.377686L88.509 0H1.16458C0.757706 0.151978 0.405136 0.278442 0.109787 0.377686C0.0730286 0.390015 0.0364227 0.406494 0 0.42688V772.112C0.0569916 774.934 0.0930023 776.687 0.105179 777.277C0.0695496 777.272 0.0345001 777.267 0 777.262V777.479Z" fill="url(#paint0_linear_317_3930)"/>
        <defs>
          <linearGradient id="paint0_linear_317_3930" x1="60.1068" y1="0.67991" x2="79.5195" y2="987.747" gradientUnits="userSpaceOnUse">
            <stop stop-color="#3D2B1F"/>
            <stop offset="1" stop-color="#A37353"/>
          </linearGradient>
        </defs>
      </svg>
    </div>

    <div class="text-content">
      <MainTitle :title="section" />

      <ul cy="categories-list">
        <li v-for="(item, index) in list"
            :key="index"
            class="text-center"
            @click="handleSelect(item)">
          <h3 class="uppercase">
            {{ item.subcategory }}
          </h3>
        </li>
      </ul>
    </div>

    <i class="close-icon block fixed"
       cy="close-icon"
       @click="handleClose">
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9.5" cy="9.5" r="9.5" fill="#845D43"/>
        <path d="M12.6088 13H11.7488L9.48875 9.92L7.23875 13H6.38875L9.04875 9.4L6.55875 6H7.40875L9.51875 8.86L11.6188 6H12.4188L9.93875 9.38L12.6088 13Z" fill="white"/>
      </svg>
    </i>
  </div>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed} from "vue";
  import MainTitle from "@/components/MainTitle.vue";
  const props = defineProps({
    section: String,
    list: Array,
  })

  const emit = defineEmits(['select']);

  const { state, dispatch, commit } = useStore()
  const showCategories = computed(() => state.menu.showCategories)

  function handleClose () {
    commit('SET_MENU', {key: 'showCategories', data: false})
  }

  function handleSelect (item) {
    emit('select', item)
  }
</script>

<style lang="scss">
  .categories-list {
    @media only screen and (min-width: 0) {
      background-image: url("../assets/bg-image.jpg");
      background-size: cover;

      .text-content {
        margin: 0 auto;
        transform: translateX(-20px);
      }
      ul {
        margin-top: 33px;
        li {
          padding-bottom: 24px;
          h3 {
            margin-bottom: 0;
            font-size: 1.6rem;
            font-weight: 600;
          }
        }
      }
      .close-icon {
        bottom: 4px;
        right: 4px;
      }
    }
  }
</style>