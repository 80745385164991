<template>
  <div class="food-view section-grid">
    <CategoriesList :section="section"
                    :list="categories"
                    @select="handleCategorySelect" />

    <CategoriesIcon />

    <div>
      <ul class="category-list"
          cy="category-list">
        <li v-for="({id, subcategory, list}, index) in data"
            :key="index"
            :id="id"
            class="text-center">
          <MainTitle :title="subcategory" />

          <ul class="items-list"
              cy="items-list">
            <li v-for="({title, ingredients, description, weight, weight_unit, price}, index) in list"
                :key="index"
                class="text-center">
              <h4 class="uppercase">
                {{ title }}
              </h4>
              <p>
                {{ description }}
              </p>
              <p>
                {{ ingredients }}
              </p>
              <p>
                ({{ weight }} {{ weight_unit }})
              </p>
              <p id="price">
                {{ price }} lei
              </p>
              <figure class="m-t-16">
                <img src="../assets/benedict.png" alt="" />
              </figure>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed} from "vue";
  import CategoriesList from "@/components/CategoriesList.vue";
  import CategoriesIcon from "@/components/CategoriesIcon.vue";
  import MainTitle from "@/components/MainTitle.vue";

  const { state, dispatch, commit, getters } = useStore()
  const data = computed(() => state.menu.data.food)
  const section = computed(() => getters.section)
  const categories = computed(() => getters.foodCategories)

  function handleCategorySelect (category) {
    commit('SET_MENU', {key: 'showCategories', data: false})
    const element = document.getElementById(category.id)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }
  }
</script>

<style lang="scss">
  .food-view {
    @media only screen and (min-width: 0) {
      h4 {
        margin-top: 33px;
        margin-bottom: 2px;
      }
      li p {
        margin: 0;
        &#price {
          margin-top: 4px;
          font-weight: 600;
        }
      }
    }
  }
</style>