<template>
  <main class="allergens-view section-grid">
    <ul class="m-t-24"
        cy="list">
      <li v-for="(item, index) in data"
          :key="index" class="text-center">
        <h3>
          {{ item.title }}
        </h3>
        <p>
          {{ item.details }}
        </p>
        <span>
          {{ item.price }} lei
        </span>
      </li>
    </ul>
  </main>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed} from "vue";

  const { state } = useStore()
  const data = computed(() => state.menu.data.cigars)
</script>

<style lang="scss">
  .allergens-view {
    @media only screen and (min-width: 0) {

    }
  }
</style>