import axios from "axios"
import api from "./api"
import router from "../../router"

export const state = {
  data: {
    food: [],
    drinks: [],
    menu: [],
    allergens: [],
    waiter: {text: '', secondary: '', placeholder: '', btn: ''},
  },
  app_menu: [
    {key: 'food', path: 'food'},
    {key: 'allergens', path: 'allergens'},
    {key: 'drinks', path: 'drinks'},
    {key: 'cigars', path: 'cigars'},
    {key: 'waiter', path: 'waiter'},
  ],
  showCategories: false
}

export const mutations = {
  SET_MENU (state, {key, data}) {
    state[key] = data
  }
}

export const actions = {
  async get_menu ({commit, rootState}, restaurantID) {
    try {
      const { data } = await axios.get(`${api.menu}/${restaurantID}?lang=${rootState.language}`)
      commit('SET_MENU', {key: 'data', data})
    } catch (err) {
      console.log(err)
    }
  }
}

export const getters = {
  foodCategories: state => state.data.food.map(o => o),
  drinksCategories: state => state.data.drinks,
  section: state => state.data.app_menu?.[router.currentRoute.value.name],
  app_menu: state => state.app_menu.map(o => ({...o, name: state.data?.app_menu?.[o.key]})),
}