import { createStore } from 'vuex'
import axios from "axios";
import * as menu from './modules/menu'
import * as waiter from './modules/waiter'
import api from './modules/api';

export default createStore({
  state: {
    restaurantID: '',
    table: '',
    language: 'ro',
    languages: [],
    content: {
      welcome: []
    }
  },
  getters: {
    content: (state) => (view) => {
      return state.content[view][state.language] || {}
    }
  },
  mutations: {
    SET_STATE (state, {key, value}) {
      state[key] = value
    },
    SET_LANGUAGE (state, lang) {
      state.language = lang
    },
    SET_CONTENT (state, {section, data}) {
      state.content[section] = data
    }
  },
  actions: {
    async get_welcome ({ state, commit }, restaurantID) {
      try {
        const url = `${api.welcome}/${restaurantID}`
        const { data: { languages, categories } } = await axios.get(url)
        commit('SET_STATE', {key: 'languages', value: languages})
        commit('SET_CONTENT', {section: 'welcome', data: categories})
      } catch (err) {
        console.log(err)
      }
    }
  },
  modules: {
    menu,
    waiter
  }
})
