import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import WelcomeView from '@/views/WelcomeView.vue'
import FoodView from "@/views/FoodView.vue";
import DrinksView from "@/views/DrinksView.vue";
import WaiterView from "@/views/WaiterView.vue";
import MenuLayout from "@/layouts/MenuLayout.vue";
import AllergensView from "@/views/AllergensView.vue";
import CigarettesView from "@/views/CigarsView.vue";

const routes = [
  {
    path: '/',
    redirect: '/rococo_7jds1'
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:restaurantID',
    name: 'welcome',
    children: [
      {
        path: '',
        name: 'welcome',
        component: WelcomeView
      }
    ]
  },
  {
    path: '/:restaurantID/menu',
    component: MenuLayout,
    children: [
      {
        path: 'food',
        name: 'food',
        component: FoodView
      },
      {
        path: 'drinks',
        name: 'drinks',
        component: DrinksView
      },

      {
        path: 'allergens',
        name: 'allergens',
        component: AllergensView
      },
      {
        path: 'cigars',
        name: 'cigars',
        component: CigarettesView
      },
      {
        path: 'waiter',
        name: 'waiter',
        component: WaiterView
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
