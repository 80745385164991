<template>
  <div class="menu-layout wh100">
    <router-view />

    <MenuFooter />
  </div>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed} from "vue";
  import MenuFooter from "@/components/MenuFooter.vue";
  import {useRoute} from "vue-router";

  const { state, dispatch, commit } = useStore()
  const { params: { restaurantID } } = useRoute();
  dispatch('get_menu', restaurantID);
</script>

<style lang="scss">
  .menu-layout {
    @media only screen and (min-width: 0) {
      background-image: url("../assets/bg-image.jpg");
      background-size: cover;
      h1 {
        text-align: center;
        text-transform: capitalize;
      }
    }
  }
</style>