<template>
  <span class="categories-icon fixed z9"
        v-show="!showCategories"
        cy="categories-icon"
        @click="handleShowCategories">
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_7_1643)">
        <g filter="url(#filter1_b_7_1643)">
          <circle cx="22.5" cy="18.5" r="18.5" fill="url(#paint0_linear_7_1643)"/>
        </g>
        <path d="M15 9.34652H30C30.6 9.34652 31 9.73959 31 10.3292V28.0173C31 28.6069 30.6 29 30 29H16C15.4 29 15 28.6069 15 28.0173V9.34652ZM15 9.34652L26.6 4.13834C27.2 3.74527 28 4.23661 28 5.02275V9.34652" stroke="#DADADA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M24.1 29L23.7 25.7572C23.6 24.4797 24 23.2022 24.9 22.3178C25.6 21.63 26 20.3525 26 18.6819V13.2772" stroke="#DADADA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20 13.2772V18.6819C20 20.3525 20.4 21.5317 21.1 22.3178C22 23.3005 22.4 24.4797 22.3 25.7572L21.9 29" stroke="#DADADA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23 19.1733V13.2772" stroke="#DADADA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <filter id="filter0_d_7_1643" x="0" y="0" width="45" height="45" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7_1643"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7_1643" result="shape"/>
        </filter>
        <filter id="filter1_b_7_1643" x="-6" y="-10" width="57" height="57" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5"/>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7_1643"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7_1643" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_7_1643" x1="22.5" y1="0" x2="22.5" y2="37" gradientUnits="userSpaceOnUse">
          <stop stop-color="#3D2B1F"/>
          <stop offset="1" stop-color="#A37353"/>
        </linearGradient>
      </defs>
    </svg>
  </span>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed} from "vue";

  const { state, commit } = useStore()
  const showCategories = computed(() => state.menu.showCategories)

  function handleShowCategories () {
    commit('SET_MENU', {key: 'showCategories', data: true})
  }
</script>

<style lang="scss">
  .categories-icon {
    @media only screen and (min-width: 0) {
      bottom: 72px;
      right: 4px;
    }
  }
</style>