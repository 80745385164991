<template>
  <div class="welcome-view wh100 relative">
    <figure class="logo">
      <img cy="logo"
           :src="`/couch-img/${restaurantID}/logo.png`"
           alt="logo" />
    </figure>

    <img src="../assets/welcome-vertical-stripe.svg"
         id="vertical-lines"
         class="absolute"
         alt="" />

    <nav cy="options-list">
      <router-link :to="`/${restaurantID}/menu/food?table=${table}`"
                   class="">
        <img src="../assets/food-category-welcome.png"
             class="m-left-auto block"
             alt="food" />
        <span class="block text-right">
          <span class="m-left-auto text-left">
            {{ content[0]?.name[language] }}
          </span>
        </span>
      </router-link>

      <router-link :to="`/${restaurantID}/menu/drinks?table=${table}`">
        <img src="../assets/waiter-category-welcome.png"
             class="m-left-auto block"
             alt="food" />
        <span class="block text-right">
          <span class="m-left-auto text-left">
            {{ content[1]?.name[language] }}
          </span>
        </span>
      </router-link>

      <router-link :to="`/${restaurantID}/menu/waiter?table=${table}`">
        <img src="../assets/drinks-category-welcome.png" alt="food" />
        <span class="block">
          <span class="text-right">
            {{ content[2]?.name[language] }}
          </span>
        </span>
      </router-link>
    </nav>

    <div class="languages flex flex-center m-t-24"
         cy="languages">
      <button v-for="(lang, index) in languages"
              :key="index"
              @click="handleLanguage(lang)"
              :class="{'selected-language': language === lang}">
        <span class="uppercase">
          {{ lang }}
        </span>
      </button>
    </div>
  </div>
</template>

<script setup>
  import {useRoute} from "vue-router";
  import {useStore} from "vuex";
  import {computed} from "vue";

  const {query: {table}, params: {restaurantID}} = useRoute();
  const {state, commit, dispatch} = useStore();
  dispatch('get_welcome', restaurantID);
  const language = computed(() => state.language);
  const languages = computed(() => state.languages);
  const content = computed(() => state.content.welcome);
  commit('SET_STATE', { key: 'restaurantID', value: restaurantID})
  commit('SET_STATE', { key: 'table', value: table})

  function handleLanguage (lang) {
    commit('SET_LANGUAGE', lang)
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .welcome-view {
    @media only screen and (min-width: 0) {
      background: linear-gradient(160.81deg, #1D262B 27.67%, #628091 192.96%);

      #vertical-lines {
        left: 10px;
        top: 117px;
      }

      .logo {
        top: 11px;
        left: 23px;
        position: absolute;
        img {
          width: 80px;
        }
      }

      nav {
        max-width: 360px;
        padding-top: 62px;
        a {
          width: 100%;
          padding: 0 24px;
          display: block;
          color: $yellow;
          font-size: 1.6rem;
          span {
            margin-top: 7px;
            span {
              padding-bottom: 4px;
              display: inline-block;
              border-bottom: 1px solid $yellow;
            }
          }
        }
        a:first-child {
          span {
            padding-right: 20px;
            span {
              margin-left: auto;
            }
          }
        }
        a:nth-child(2) {
          margin-top: 24px;
          > span {
            span {
              font-size: 1.4rem;
            }
          }
        }
        a:last-child {
          margin-top: -100px;
          padding-left: 50px;
          > span {
            margin-top: -1px;
            margin-left: 30px;
            padding-right: 20px;
            span {
              padding-left: 20px;
              margin-left: auto;
            }
          }
        }
      }

      .languages {
        button {
          width: 40px;
          height: 40px;
          margin: 0 8px;
          display: block;
          border: none;
          border-radius: 50%;
          font-size: 1.8rem;
          color: black;
          text-align: center;
          padding: 0;
        }
        .selected-language {
          background-color: $yellow;
        }
      }
    }
  }
</style>
