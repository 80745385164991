<template>
  <div class="home-view flex-center">
    <h1>PrestoQR</h1>
  </div>
</template>

<script setup>

</script>

<style lang="scss">
  .home-view {
    @media only screen and (min-width: 0) {

    }
  }
</style>