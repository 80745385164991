export const icons = {
  drinks: `<svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.9789 23.3382C22.8822 23.0363 22.4789 22.8507 22.0785 22.9237L20.0549 23.2925L17.5053 15.3441C18.7216 14.6708 19.2242 12.8087 19.0378 9.68726C18.8573 6.66822 18.0982 3.42978 17.503 1.5739C17.4062 1.27203 17.003 1.08653 16.6024 1.15949C16.5592 1.16736 16.5183 1.17838 16.479 1.19135C16.4361 1.19338 16.3929 1.19765 16.3496 1.20552L8.26125 2.67947C7.86077 2.7525 7.61457 3.05631 7.71136 3.35825C8.04489 4.39807 9.27261 8.03156 11.0685 11.1296C12.7565 14.0416 14.3977 15.5234 16.0624 15.631L18.6044 23.5568L16.7704 23.891C16.3699 23.964 16.1237 24.2678 16.2205 24.5697C16.3031 24.8272 16.6085 25.0001 16.9451 25.0001C17.0031 25.0001 17.0621 24.9949 17.121 24.9842L19.68 24.5179H19.6803C19.6803 24.5179 19.6805 24.5178 19.6807 24.5178L22.429 24.0169C22.8295 23.944 23.0757 23.6401 22.9789 23.3382ZM9.34175 3.63965L16.2204 2.38615C16.4697 3.23379 16.7225 4.25066 16.9417 5.33426L10.7789 7.36245C10.1162 5.84442 9.62306 4.47192 9.34175 3.63965ZM12.4357 10.6793C12.0111 9.94669 11.619 9.18235 11.2636 8.43023L17.1576 6.49051C17.343 7.57546 17.4844 8.68928 17.547 9.73794C17.7597 13.2979 16.967 14.4026 16.465 14.494C15.4357 14.6821 13.9291 13.2556 12.4357 10.6793Z" fill="#ffffff"/>
              <path d="M9.46274 0.356997C9.41868 0.355198 9.37541 0.356922 9.33283 0.360746C9.29085 0.353323 9.24758 0.348075 9.20301 0.3462L0.894285 0.000920366C0.485345 -0.0165498 0.138377 0.216786 0.109529 0.522402C0.10923 0.525476 0.108534 0.5284 0.108236 0.531474C0.0301484 1.5997 -0.183225 5.34575 0.352148 8.70234C0.855391 11.8575 1.86038 13.6327 3.41329 14.0973L2.81783 22.2392L0.933777 22.1609C0.523543 22.1446 0.170408 22.3813 0.147727 22.6915C0.125047 23.0016 0.440183 23.2669 0.851611 23.2839L6.30432 23.5105C6.31835 23.5111 6.33227 23.5114 6.3461 23.5114C6.73932 23.5114 7.06849 23.2795 7.09037 22.98C7.11305 22.6699 6.79791 22.4046 6.38649 22.3875L4.30776 22.3011L4.90491 14.1367C6.327 13.7562 7.5201 12.0816 8.53484 9.05205C9.51626 6.12193 10.0273 2.85598 10.1667 0.949484C10.1892 0.639369 9.87417 0.374092 9.46274 0.356997ZM8.13953 4.70047L1.49511 4.17089C1.47989 2.93059 1.52207 1.85531 1.56186 1.15508L8.62805 1.4487C8.53564 2.38661 8.37131 3.5155 8.13953 4.70047ZM4.23365 13.0961C3.17594 13.0521 2.27868 11.3595 1.83353 8.56813C1.66114 7.48715 1.56902 6.36299 1.52525 5.30428L7.90507 5.81272C7.67767 6.81714 7.40461 7.83116 7.08798 8.77672C5.93069 12.2317 4.75152 13.1177 4.23365 13.0961Z" fill="#ffffff"/>
            </svg>`,
  allergens: `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.73342 19.2665L1 24" stroke="#DADADA"/>
<path d="M24 1L19.4572 5.54283" stroke="#DADADA"/>
<path d="M12.5001 17.5194L16.0581 15.4862V17.5194L12.5001 19.5525" stroke="#DADADA"/>
<path d="M12.5001 15.4863L16.0581 13.4532V15.4863L12.5001 17.5195" stroke="#DADADA"/>
<path d="M12.5001 13.4531L16.0581 11.4199V13.4531L12.5001 15.4862" stroke="#DADADA"/>
<path d="M12.5001 11.4199L16.0581 9.38672V11.4199L12.5001 13.453" stroke="#DADADA"/>
<path d="M12.5001 9.38673L16.0581 7.35358V9.38673L12.5001 11.4199" stroke="#DADADA"/>
<path d="M12.5 17.5194L8.94202 15.4862V17.5194L12.5 19.5525" stroke="#DADADA"/>
<path d="M12.5 15.4863L8.94202 13.4532V15.4863L12.5 17.5195" stroke="#DADADA"/>
<path d="M12.5 13.4531L8.94202 11.4199V13.4531L12.5 15.4862" stroke="#DADADA"/>
<path d="M12.5 11.4199L8.94202 9.38672V11.4199L12.5 13.453" stroke="#DADADA"/>
<path d="M12.5 9.38673L8.94202 7.35358V9.38673L12.5 11.4199" stroke="#DADADA"/>
<path d="M12.5001 2.78534V23.6188" stroke="#DADADA" stroke-linecap="round"/>
<path d="M8.94202 5.06628V7.35358" stroke="#DADADA" stroke-linecap="round"/>
<path d="M16.0581 5.06628V7.35358" stroke="#DADADA" stroke-linecap="round"/>
</svg>`,
  food: `<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.4331 20.0574L9.32456 15.0243L11.1046 17.446L7.67364 22.0998C6.35964 23.8806 3.92818 22.004 5.4331 20.0574ZM9.97781 8.59566C10.3484 7.30593 10.1781 6.20388 8.78548 4.56677L5.38257 0.200489C4.89964 -0.404442 3.76159 0.483988 4.23703 1.21669L6.95861 4.79238C7.47336 5.4592 6.51687 6.34164 5.98528 5.65485L3.17011 1.95738C2.64601 1.31652 1.56224 2.16302 2.11067 2.89573C2.90431 3.91393 4.13221 5.57699 4.92585 6.59519C5.47616 7.19214 4.63946 8.07657 4.09665 7.33189L1.30206 3.65838C0.892139 3.18322 0.222038 3.4787 0.0442177 4.03372C-0.146705 4.62667 0.323115 5.0599 0.643191 5.49913L3.7616 9.79554C4.72931 10.9914 5.81121 11.7002 7.06905 11.2989C7.26184 11.237 7.49769 11.1112 7.75038 10.9455L16.1285 22.4811C16.5796 23.102 17.45 23.1679 18.0153 22.6668L18.1444 22.553C18.7808 21.988 18.8838 20.9558 18.3391 20.289L9.46681 9.41621C9.72512 9.10875 9.91417 8.81726 9.97781 8.59566ZM12.3494 11.1112L13.7289 9.32636C11.9544 4.63465 18.53 -1.6183 21.7177 1.25662C25.5904 4.75245 19.8347 13.1776 15.7897 11.0952L14.1556 13.3093L12.3494 11.1112Z" fill="#DADADA"/>
</svg>`,
  cigars: `<svg width="15" height="23" viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.3182 3.44247H13.0299C12.6534 3.44247 12.3481 3.70919 12.3481 4.0381V4.12173C12.3481 4.2573 12.3207 4.38714 12.2727 4.50871V2.73992C12.2727 2.41101 11.9675 2.14428 11.5909 2.14428H9.54545H7.5H6.13636V0.595634C6.13636 0.266725 5.83105 0 5.45455 0H3.40909C3.03259 0 2.72727 0.266725 2.72727 0.595634V4.50871C2.67934 4.38714 2.652 4.2573 2.652 4.12173V4.0381C2.652 3.70919 2.34675 3.44247 1.97018 3.44247H0.681818C0.305318 3.44247 0 3.70919 0 4.0381V21.1056C0 22.1502 0.972682 23 2.16825 23H12.8317C14.0273 23 15 22.1502 15 21.1056V4.0381C15 3.70913 14.6947 3.44247 14.3182 3.44247ZM10.9091 5.14616H10.2273V3.33555H10.9091V5.14616ZM8.86364 5.14616H8.18182V3.33555H8.86364V5.14616ZM6.81818 3.33555V5.14616H6.13636V3.33555H6.81818ZM4.09091 1.19127H4.77273V2.73992V5.14616H4.09091V1.19127ZM4.01564 6.50427H10.9844C12.2687 6.50427 13.3485 5.72482 13.6364 4.67889V13.6911L7.95061 9.31828C7.69309 9.12017 7.30711 9.12029 7.04959 9.31828L1.36357 13.6911V4.67895C1.65157 5.72482 2.73136 6.50427 4.01564 6.50427ZM12.8318 21.8087H2.16825C1.72459 21.8087 1.36364 21.4933 1.36364 21.1056V15.2783L7.50014 10.559L13.6364 15.2783V21.1056C13.6364 21.4933 13.2754 21.8087 12.8318 21.8087Z" fill="#DADADA"/>
</svg>`,
  waiter: `<svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.15833 19.9445V17.6844C4.15833 17.6844 6.29747 17.5712 6.29747 15.4055V8.19577C6.29747 8.19577 6.27532 2.21759 12.334 2.21759C17.6752 2.21759 18.9773 5.52045 18.9773 7.97873V15.8065C18.9773 15.8065 18.8179 17.6939 21.1032 17.6939V19.9445H4.15833Z" stroke="#DADADA"/>
<path d="M14.2738 2.3875V1.76467C14.2738 1.29665 14.0993 0.847801 13.7886 0.516861C13.478 0.18592 13.0567 0 12.6174 0C12.1781 0 11.7568 0.18592 11.4461 0.516861C11.1355 0.847801 10.961 1.29665 10.961 1.76467V2.33088" stroke="#DADADA"/>
<path d="M14.3137 19.9446C14.2894 20.4985 14.0657 21.021 13.6892 21.4038C13.3127 21.7865 12.8123 22.0001 12.2919 22.0001C11.7715 22.0001 11.2711 21.7865 10.8946 21.4038C10.5181 21.021 10.2945 20.4985 10.2701 19.9446" stroke="#DADADA"/>
<path d="M20.5894 11.6827C20.9009 11.8229 21.1754 12.0423 21.3889 12.3217C21.6024 12.6011 21.7483 12.932 21.8139 13.2855C21.8794 13.6389 21.8627 14.0041 21.765 14.349C21.6673 14.694 21.4918 15.0081 21.2537 15.264" stroke="#DADADA"/>
<path d="M21.8295 10.0361C22.3637 10.2752 22.8347 10.6503 23.2012 11.1284C23.5677 11.6065 23.8186 12.1731 23.9318 12.7785C24.045 13.3839 24.0171 14.0097 23.8506 14.601C23.684 15.1922 23.3839 15.731 22.9765 16.17" stroke="#DADADA"/>
<path d="M4.42392 11.8006C4.11127 11.9393 3.83548 12.1579 3.62087 12.4371C3.40626 12.7162 3.25944 13.0474 3.19336 13.4013C3.12727 13.7552 3.14395 14.121 3.24193 14.4664C3.3399 14.8118 3.51617 15.1262 3.75516 15.3818" stroke="#DADADA"/>
<path d="M3.17062 10.1586C2.63642 10.3977 2.16542 10.7728 1.79889 11.2509C1.43237 11.729 1.18151 12.2956 1.06829 12.901C0.955074 13.5064 0.982958 14.1322 1.1495 14.7235C1.31604 15.3147 1.61615 15.8535 2.02354 16.2925" stroke="#DADADA"/>
</svg>`
}