<template>
  <div class="navigation-container">
    <!-- U-shaped slider with your custom SVG -->
    <div class="u-shape-wrapper" ref="uShape">
      <svg width="72" height="33" viewBox="0 0 72 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.026292 0C0.0175324 3.80578e-06 0.00876843 5.71363e-06 0 5.72205e-06C10.8186 5.72205e-06 14.0198 7.85929 17.2738 15.8482C20.6368 24.1049 24.0563 32.5 36 32.5C48.0112 32.5 51.5322 24.0097 54.9748 15.7082C58.2677 7.76773 61.489 5.72205e-06 72 5.72205e-06C71.9914 5.72205e-06 71.9828 3.81419e-06 71.9743 0H0.026292Z"
            fill="#FFFFFF"
        />
      </svg>
    </div>

    <!-- Navigation items -->
    <div class="items-container"
         cy="menu-items">
      <button
          v-for="({name, path}, index) in menuItems"
          :key="index"
          class="item"
          :cy="`menu-${path}`"
          :class="{ active: activeIndex === index }"
          @click="moveTo({index, path})"
          ref="dots"
      >
        <i :class="{'only-icon': (index + 1) % 2 === 0}"
           class="block"
           v-html="icons[path]" />
        <span>
          {{ name }}
        </span>
      </button>
    </div>
  </div>
</template>

<script setup>
  import {ref, computed, watch, nextTick, onMounted} from 'vue';
  import gsap from 'gsap';
  import {useRoute} from "vue-router";
  import {useStore} from "vuex";
  import router from "@/router";
  import {icons} from "@/store/data";

  const { getters } = useStore();
  const route = useRoute();
  const uShape = ref(null);
  const dots = ref([]);
  const activeIndex = ref(0);
  const menuItems = computed(() => getters.app_menu);

  async function moveTo ({ index, path }) {
    // Wait for the next tick to ensure DOM is updated
    await nextTick()

    // Add null checks
    if (!dots.value || !dots.value[index]) {
      console.warn('Menu items not yet rendered')
      return
    }

    const targetDot = dots.value[index]
    const dotRect = targetDot.getBoundingClientRect()
    const containerRect = targetDot.parentElement.getBoundingClientRect()

    // Calculate the target position (centered on the item)
    const dotCenterX = dotRect.left + (dotRect.width / 2)
    const containerLeft = containerRect.left
    const uShapeWidth = 72 // Width of your SVG
    const targetX = dotCenterX - containerLeft - (uShapeWidth / 2)

    // Animate the U-shape movement
    gsap.to(uShape.value, {
      x: targetX,
      duration: 0.5,
      ease: "power2.out"
    })

    // Update active state and animate item color
    setTimeout(() => {
      activeIndex.value = index
    }, 100)

    // Only proceed with navigation if path is provided
    if (path) {
      await router.push(`${path}?table=${route.query.table}`)
    }
  }

  // Watch for menuItems changes and initialize position
  watch(menuItems, async () => {
    // Wait for the next tick to ensure DOM is updated
    await nextTick()

    const index = menuItems.value?.findIndex(o => o.path === route.name)

    // Only move if we found a valid index
    if (index !== -1) {
      await moveTo({ index })
    }
  }, { immediate: true })

  // Add mounted hook to handle initial position
  onMounted(async () => {
    await nextTick()
    const index = menuItems.value?.findIndex(o => o.path === route.name)
    if (index !== -1) {
      await moveTo({ index })
    }
  })
</script>

<style>
  .navigation-container {
    width: 100%;
    height: 63px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: linear-gradient(360deg, #3D2B1F 0%, #A37353 189.68%);
    overflow: hidden;
    border-radius: 10px 10px 0 0;

    .u-shape-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .items-container {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 20px;
    }

    .item {
      background-color: transparent;
      border: none;
      cursor: pointer;
      i {
        transition: transform 0.1s ease-in-out;
      }
      span {
        color: white;
      }
    }

    /* Remove default button styles */
    .item:focus {
      outline: none;
    }

    /* Optional: Add active state styles beyond color */
    .item.active {
      transform: scale(1.1);
      i:not(.only-icon) {
        transform: translateY(-4px);
      }
      &:not(:nth-child(2)) {
        path {
          fill: #3D2B1F;
        }
      }
      &:nth-child(2) {
        .only-icon {
          path {
            stroke: #3D2B1F;
          }
        }
      }
    }

    .only-icon {
      transform: translateY(-12px);
    }
  }
</style>