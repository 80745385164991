import axios from "axios"
import api from "@/store/modules/api"

export const state = {}

export const mutations = {
  SET_STATE (state, {key, value}) {
    state[key] = value
  }
}

export const actions = {
  async call_waiter ({commit}, {payType, message, restaurantID, table}) {
    try {
      const table = localStorage.getItem('table') || '1'
      let title = `Masa ${table}`
      if (message) {
        title += ` 💬`
      }
      if (payType === 'cash') {
        title = `Masa ${table} 💰`
        message = `plata CASH \n${message}`
      }
      if (message === 'card') {
        title = `Masa ${table} 💳`
        message = `plata CARD \n${message}`
      }
      const { data } = await axios.post(`${api.waiter}/${restaurantID}?table=${table}`, {payType, message})
      console.log(data);
    } catch (err) {
      console.log(err)
    }
  }
}