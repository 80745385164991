<template>
  <main class="allergens-view section-grid">
    <ul class="m-t-24"
        cy="list">
      <li v-for="(item, index) in data"
          :key="index">
        <h3>
          {{ item.title }}
        </h3>
        <p>
          {{ item.value }}
        </p>
        <p>
          <i>
            {{ item.ingredients }}
          </i>
        </p>
      </li>
    </ul>
  </main>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed} from "vue";

  const { state } = useStore()
  const data = computed(() => state.menu.data.allergens)
</script>

<style lang="scss">
  .allergens-view {
    @media only screen and (min-width: 0) {
      padding-top: 24px;
      li {
        margin-bottom: 16px;
        h3 {
          margin-bottom: 4px;
        }
        p {
          margin: 2px 0;
          font-size: 1.5rem;
        }
      }
    }
  }
</style>